import React from 'react';
import { Box, Image, Button, LinkBox, LinkOverlay, Container, Heading } from '@chakra-ui/react';
import ReactPlayer from 'react-player/youtube';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import Seo from '../../components/sections/seo';

import HeroWithLeftMedia from '../../components/sections/herowithleftmedia';
import HeroWithRightMedia from '../../components/sections/herowithrightmedia';
import Carousel from '../../components/sections/carousel';

import LeatherWorkingGroup from '../../components/ui/corporateresponsibility/sustainingplaces/LeatherWorkingGroup.jpg';
import GreenChemistry from '../../components/ui/corporateresponsibility/sustainingplaces/green-chemistry.jpg'; 
import Fishing from '../../components/ui/corporateresponsibility/sustainingplaces/Fishing.jpg';
import PoweringBusiness from '../../components/ui/corporateresponsibility/sustainingplaces/Powering_our_Business_Carousel_Item.jpg';
import Rethreads from '../../components/ui/corporateresponsibility/sustainingplaces/Rethreads.jpg';

export default function SustainingPlacesTabPanel() {
  const cards = [
    {image: PoweringBusiness, heading: "POWERING OUR BUSINESS AND PRODUCTS WITH CLEAN ENERGY", text: "Reducing our carbon footprint by reducing energy use and increasing the use of renewable energy, both in our owned and operated facilities and in our supply chain."},
    {image: Rethreads, heading: "MAKING RESOURCES LAST LONGER THROUGH RECYCLING & CLOSED LOOP PRACTICES", text: "Recapturing and reusing our finite resources to contribute to a circular system that sustains our planet."},
    {image: GreenChemistry, heading: "ADOPTION OF GREEN CHEMISTRY", text: "Increasing our adoption of sustainable chemistry to reduce risk that hazardous chemicals pose to our workers, consumers and the environment."},
    {image: Fishing, heading: "IMPROVING WATER SUSTAINABILITY", text: "Reducing our water footprint by buying materials that are dyed without water, following strict usage regulations in our mills, and investing in clean water for communities where we manufacture."}
  ];
  return (
    <Box>
      <Seo
        title="Corporate Responsibility - Sustaining Places"
        description="Minimizing environmental impact on the planet."
        //links={[{"rel": "canonical", "href": "https://www.columbiasportswearcompany.com/careers"}]}
      />
      <Container maxW='6xl' my={14} className='flow'>
        <Heading as={'h1'} mt={4} mb={3}>Sustaining Places</Heading>
        <Carousel cards={cards} />
        <Heading textAlign={'center'}>FEATURED INITIATIVES</Heading>
        <HeroWithRightMedia
            media={<ReactPlayer url="https://www.youtube.com/watch?v=cDW_eHKoLwI" />}
            title={<Heading fontSize="3xl"> PLANET WATER </Heading>}
            subTitle={"Access to clean water is a pressing global issue. We're proud to partner with the Planet Water Foundation, a U.S. nonprofit that helps combat our global water crisis by bringing clean water to the world's most disadvantaged communities."}
            cta={
              <LinkBox as='a'>
                <LinkOverlay href='https://planet-water.org/' isExternal>
                  <Button bg='black' color='white' size='lg' borderRadius={0} rightIcon={<ExternalLinkIcon />}>Read More</Button>
                </LinkOverlay>
              </LinkBox>
            }
        />
        <HeroWithLeftMedia 
            media={<ReactPlayer url="https://www.youtube.com/watch?v=oa0ybTF_QOU"/>}
            title={<Heading fontSize="3xl"> RETHREADS </Heading>}
            subTitle={"At Columbia Sportswear Company, we are committed to taking a holistic, life cycle approach to managing the environmental impacts of the products we make. This includes extending the life of our products and keeping these products out of landfills once they have reached the end of their wearable life."}
            cta={
              <LinkBox as='a'>
                <LinkOverlay href='https://www.columbia.com/ReThreads.html' isExternal>
                  <Button bg='black' color='white' size='lg' borderRadius={0} rightIcon={<ExternalLinkIcon />}>Read More</Button>
                </LinkOverlay>
              </LinkBox>
            }
        />
        <HeroWithRightMedia
            media={<Image src={LeatherWorkingGroup}/>}
            title={<Heading fontSize="3xl"> LEATHER WORKING GROUP </Heading>}
            subTitle={"Columbia Sportswear Company is committed to supporting responsible leather manufacturing across the globe, and that is why we are proud members of the Leather Working Group (LWG). The LWG aims to improve the leather manufacturing industry by creating alignment on environmental priorities, bringing visibility to best practices and providing suggested guidelines for continual improvement. In 2021, 100% of our footwear leather manufacturing partners were LWG rated and our team continues to prioritize leather sourcing from LWG tanneries."}
            cta={
              <LinkBox as='a'>
                <LinkOverlay href='https://www.leatherworkinggroup.com/' isExternal>
                  <Button bg='black' color='white' size='lg' borderRadius={0} rightIcon={<ExternalLinkIcon />}>Read More</Button>
                </LinkOverlay>
              </LinkBox>
            }
        />
        </Container>
    </Box>
  );
}